import { graphql, navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import { ProductCard } from "../components/Card/types"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"

export const query = graphql`
  query NewsPageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_news: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "news" } } }
    ) {
      ...StaticContentFragment
    }
  }
`

const NewsPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const content = createStaticContent(props.data, "news")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{getPageTitle(content)}</title>
        <script
          src="https://www.sociablekit.com/app/embed/linkedin-page-post/widget.js"
          async
          defer
        ></script>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle
          title={formatStaticContent(content, "news-title")}
          desc={formatStaticContent(content, "news-desc")}
          bg_icon="lineart3"
        />
        <div class="sk-ww-linkedin-page-post" data-embed-id="50619"></div>
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default NewsPage
